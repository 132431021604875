import * as React from "react";

import Layout from "../components/layout";

import OverrunImage from "../images/overrun/title-image.jpg";
import GooglePlay from "../images/google-play-badge.png";
import AppStore from "../images/app-store-badge.png";
import Adam from "../images/adam.jpg";

const IndexPage = () => {
  return (
    <Layout>
      <div className="bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg element josh-js"
                 data-josh-anim-name="fadeInLeft"
                 data-josh-anim-delay="2s"
                 data-josh-duration="1000ms">
              <img
                className="col-img"
                src={OverrunImage}
                alt="Overrun title image"
              />
            </div>
            <div className="col-lg element josh-js"
                 data-josh-anim-name="fadeInRight"
                 data-josh-anim-delay="2s"
                 data-josh-duration="1000ms">
              <h1 
                  className="text-center text-left-lg my-3 element josh-js"
                  data-josh-anim-name="bounceIn"
                  data-josh-anim-delay="2s"
                  data-josh-duration="1000ms">
                OVERRUN!
              </h1>
              <p className="element josh-js"
                 data-josh-anim-name="fadeIn"
                 data-josh-anim-delay="2s"
                 data-josh-duration="1000ms">
                Overrun throws you into a post apocalypse zombie world where your survival is on the line. Build a defense against the zombies, equip your survivors with devastating weapons, and fight the horde of zombies to progress to each new map location.
              </p>
              <p className="element josh-js"
                 data-josh-anim-name="fadeIn"
                 data-josh-anim-delay="2s"
                 data-josh-duration="1000ms">
                Unlock new weapons, turrets, and barricades to hold back the zombies as you fight for your survival. Recruit new survivors to your team and level them up until they are the ultimate zombie slayers!
              </p>
              <div className="row text-center my-5element josh-js"
                   data-josh-anim-name="fadeIn"
                   data-josh-anim-delay="2s"
                   data-josh-duration="1000ms">
                <div className="col">
                  <a href="https://play.google.com/store/apps/details?id=wildfiregames.zwg">
                    <img
                      className="store-badge element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                      src={GooglePlay}
                      alt={"Download on Google Play"}
                    />
                  </a>
                </div>
                <div className="col">
                  <a href="https://apps.apple.com/app/id1534241963">
                    <img
                      className="store-badge element josh-js"
                      data-josh-anim-name="bounceIn"
                      data-josh-anim-delay="2s"
                      data-josh-duration="1000ms"
                      src={AppStore}
                      alt={"Download on the Apple App Store"}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container py-5">
        <div className="element josh-js"
             data-josh-anim-name="fadeIn"
             data-josh-anim-delay="2s"
             data-josh-duration="2000ms">
          <h3>
            ABOUT US
          </h3>
  
          <p className="my-4 font-weight-bold">Wildfire Games Ltd was founded in March 2018 with the goal of making great games for mobile that don't break the bank. We believe mobile games should provide value through entertainment rather than being a source of addiction.</p>
          <hr />
        </div>
        <div className="row my-5">
          <div className="col-lg-8 col-md-6 text-left">
            <p className="element josh-js"
               data-josh-anim-name="fadeInLeft"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
              Hi, my name is Adam Lewis and I am the founder of Wildfire Games!
              I started my game development journey back in 2013 whilst studying
              for my degree at Sheffield University and i've been hooked ever
              since. My first completed game was released on Android and was
              called 'Gravity Sim' it was a small simulation game that allowed
              you to play with gravity.
            </p>
            <p className="element josh-js"
               data-josh-anim-name="fadeInLeft"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
              I discovered Unity in 2015 and began working on a 3d version of
              Gravity Sim and gave it the super creative name 'Gravity Sim 3D'.
              This was a turning point for me because I realised after this
              release that I could actually make money doing what I love!
            </p>
            <p className="element josh-js"
               data-josh-anim-name="fadeInLeft"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
              When we graduated, my friend and I decided to make a similar game
              to Gravity Sim 3D but with more depth and turn it in to a real
              game. We founded Pocket Labs together and released{" "}
              <a href="http://pocketuniverse.co.uk" target="_blank">
                Pocket Galaxy
              </a>{" "}
              in June 2016.
            </p>
            <p className="element josh-js"
               data-josh-anim-name="fadeInLeft"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
              I spent the next few years learning and improving my game
              development skills and spent 18 months in New Zealand before
              starting development on Overrun in January 2020 and going full
              time as a game developer.
            </p>
          </div>

          <div className="col-lg-4 col-md-6 text-center element josh-js"
               data-josh-anim-name="fadeInRight"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
            <img
              className="col-img"
              src={Adam}
              alt={"Adam Lewis profile picture"}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
